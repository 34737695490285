import _fetch from '../_fetch';
import 'url-search-params-polyfill';

const { REACT_APP_API_URL } = process.env;

export default function logoutUser(userEmail, options = {}) {
  const url = `${REACT_APP_API_URL}/users/logout`;
  const config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    redirect: 'follow',
    ...options.reqConfig
  };
  return _fetch(url, {
    withAuth: true,
    ...options
  }, config);
}
