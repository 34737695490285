import _fetch from '../_fetch';
import makeUrlSearchParams from '../utils/makeUrlSearchParams';

const { REACT_APP_API_URL } = process.env;

export default function setNextEpisode(data, options = {}) {
  let tempData = {
    ...data,
    cmd: 'set_next_episode'
  }
  let urlencoded = makeUrlSearchParams(tempData);

  const url = `${REACT_APP_API_URL}/cpanel/`;
  const config = {
    method: 'POST',
    body: urlencoded,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    redirect: 'follow',
    ...options.reqConfig
  };
  return _fetch(url, {
    withAuth: true,
    ...options
  }, config);
}
