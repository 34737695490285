import * as ActionTypes from '../actiontypes/index';

export const initialState = {
  list: [],
  loading: false,
  error: ''
};

function games(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GAMES_FETCH_SUCCESS:
      return {
        ...state,
        list: action.results,
        loading: false,
        error: ''
      };
    case ActionTypes.GAMES_FETCH_IN_PROGRESS:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.GAMES_FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case ActionTypes.ADD_GAME_IN_PROGRESS:
      return {
        ...state,
        loading: true
      }
    case ActionTypes.ADD_GAME_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: ''
      }
    }
    case ActionTypes.ADD_GAME_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }
    default:
      return {...state};
  }
}

export const selectors = {
  fetchGames: state => state.list,
  isLoading: state => state.loading
};

export default games;
