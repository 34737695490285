import { combineReducers } from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import collocateSelectors from '../redux-utils/collocate-selectors';
import games, { selectors as gamesSelectors } from './games';
import questions, { selectors as questionsSelectors } from './questions';
import cpanel from './cpanel';
import user from './user';

// Reducer Configs
const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['session_id', 'loggedin', 'authenticated', 'email', 'nickname']
};
const cpanelPersistConfig = {
  key: 'cpanel',
  storage,
  whitelist: ['state', 'question_seq']
};

const rootReducer = combineReducers({
  cpanel: persistReducer(cpanelPersistConfig, cpanel),
  games,
  questions,
  user: persistReducer(userPersistConfig, user)
});

export const {
  fetchGames,
  isFetchingGames
} = collocateSelectors(gamesSelectors, 'games');

export const {
  getQuestions,
  isLoading,
  getQuestion
} = collocateSelectors(questionsSelectors, 'questions');

export const {
  getState,
  isStateLoading,
  getActiveQuestion,
  getActiveAnswers
} = collocateSelectors(questionsSelectors, 'cpanel');

// export const {
//   isUserLoading,
//   isLoggedIn,
//   isAuthenticated
// } = collocateSelectors(userSelectors, 'user');

export default rootReducer;
