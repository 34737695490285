import * as ActionTypes from '../actiontypes/index';

export const initialState = {
  list: [],
  loading: false,
  error: ''
};

function questions(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.QUESTIONS_FETCH_SUCCESS:
      return {
        ...state,
        list: action.results,
        loading: false,
        error: ''
      };
    case ActionTypes.QUESTIONS_FETCH_IN_PROGRESS:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.QUESTIONS_FETCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case ActionTypes.ADD_QUESTION_IN_PROGRESS:
      return {
        ...state,
        loading: true
      }
    case ActionTypes.ADD_QUESTION_SUCCESS: {
      return {
        ...state,
        list: action.results,
        loading: false,
        error: ''
      }
    }
    case ActionTypes.ADD_QUESTION_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }
    case ActionTypes.DELETE_QUESTION_IN_PROGRESS:
      return {
        ...state,
        loading: true
      }
    case ActionTypes.DELETE_QUESTION_SUCCESS: {
      return {
        ...state,
        list: action.results,
        loading: false,
        error: ''
      }
    }
    case ActionTypes.DELETE_QUESTION_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }
    default:
      return {...state};
  }
}

export const selectors = {
  getQuestions: state => state.list,
  isLoading: state => state.loading,
  getQuestion: (state, question_seq) => {
    //the question_seq is 1-based but the state list is an array so 0-based.
    //That's why we add and '1'
    return state.list.find(q => q.question_seq === question_seq);
  }
};

export default questions;
