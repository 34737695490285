import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Button, PageHeader } from 'antd';
import './ActiveGameHeader.scss';
import FormItem from 'antd/lib/form/FormItem';

const ActiveGameHeader = (
  {
    gameId,
    adsButtonClick,
    errorButtonClick,
    onSwitchChange,
    redAlertMode,
    disabledSwitch,
    setSwitchRef
  }) => {

  return (
    <PageHeader
      ghost={false}
      title={`Επεισόδιο ${gameId}`}
      extra={[
        <div key="3">
          <FormItem label="ΕΝΕΡΓΟΠΟΙΗΣΗ ΕΝΕΡΓΕΙΩΝ">
            <Switch
              key="3"
              onChange={onSwitchChange}
              disabled={disabledSwitch}
              ref={setSwitchRef}
            />
          </FormItem>
        </div>,
        <Button
          className="warning-btn"
          key="2"
          disabled={!redAlertMode}
          onClick={() => adsButtonClick()}
        >
          Διαφημίσεις
        </Button>,
        <Button
          className="error-btn"
          key="1"
          disabled={!redAlertMode}
          onClick={() => errorButtonClick()}
        >
          Σφάλμα
        </Button>
      ]}
    >
    </PageHeader>
  )
};

ActiveGameHeader.propTypes = {
  gameId: PropTypes.string,
  adsButtonClick: PropTypes.func,
  errorButtonClick: PropTypes.func,
  onSwitchChange: PropTypes.func,
  redAlertMode: PropTypes.bool,
  disabledSwitch: PropTypes.bool,
  setSwitchRef: PropTypes.object
};

export default ActiveGameHeader;
