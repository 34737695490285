import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  PageHeader,
  Divider,
  List,
  Card,
  Tabs
} from 'antd';
import FeaturedGameCard from '../../components/FeaturedGameCard';
import GameItemCard from '../../components/GameItemCard';
import * as CpanelActions from '../../store/actions/cpanel';
import * as GamesActions from '../../store/actions/games';
import { getGameHistory } from '../../api/cpanel';
import './GamesList.scss';
import { PlusCircleFilled } from '@ant-design/icons';

const { TabPane } = Tabs;

const GameList = props => {
  const [games, setGames] = useState([]);
  const [sortedGames, setSortedGames] = useState([]);
  const [nextEpisode, setNextEpisode] = useState('');
  const [fgame, setFgame] = useState({});
  const [previousEpisodes, setPreviousEpisodes] = useState([]);
  const [gameStartDate, setGameStartDate] = useState('');
  const [gamesWithLB, setGamesWithLB] = useState([]);

  const getFeaturedEpisode = () => {
    if (games.length === 0) {
      return false;
    }
    // const gamesSorted = games;
    let fgame = games.find(game => game.game_id === nextEpisode);
    setFgame(fgame);
  }

  const sortGames = () => {
    if (games.length === 0) {
      return false;
    }
    const tsnow = moment(Date.now()).unix();
    let oldGames = [];
    let upcomingGames = [];

    // eslint-disable-next-line array-callback-return
    games.map(game => {
      if (game.end_timestamp != null && game.end_timestamp < tsnow) {
        oldGames.push(game);
      } else {
        upcomingGames.push(game);
      }
    });

    let oldGamesSorted = oldGames.sort((a, b) => {
      return (parseInt(a.end_timestamp) < parseInt(b.end_timestamp) ? 1 : -1);
    });

    setPreviousEpisodes(oldGamesSorted);
    const gamesNotSorted = [...upcomingGames];

    let gamesSorted = gamesNotSorted.sort((a, b) => {
      return (parseInt(a.game_id) > parseInt(b.game_id) ? 1 : -1);
    });
    setSortedGames(gamesSorted);
  }

  const startGame = (gameId) => {
    props.history.push(`/active-game/${gameId}`)
  }

  const isFeatured = (gameId) => {
    return nextEpisode === gameId;
  }

  // 'Component did update' like useEffect hook
  useEffect(() => {
    getFeaturedEpisode();
    sortGames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [games, nextEpisode]);

  // 'Component did update' like useEffect hook
  useEffect(() => {
    setGames(props.games);
  }, [props.games]);

  useEffect(() => {
    props.getGames()
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    // props.getGames()
    //   .catch(err => console.log(err));
    props.getState()
      .then(res => {
        setNextEpisode(res.game_id);
        setGameStartDate(res.game_start);
      })
      .catch(err => {
        console.log(err);
      });

    //Get the history of the episodes that have played on the tv in order to get the respective leaderboards
    getGameHistory(0)
      .then(res => {
        setGamesWithLB(res.map(item => {
          return item.game_id.toString();
        }));
      })
      .catch(err => {
        console.log(err);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="game-list">
      <PageHeader
        ghost={false}
        title="Λίστα Εκπομπών"
        extra={[
          <Link className="add-episode" key="1" to={'/game'}>
            <PlusCircleFilled className="mr-3" />
            <span className="link-copy">Προσθήκη Εκπομπής</span>
          </Link>
        ]}
      >
      </PageHeader>
      <Divider />
      <div className="mb-5">
        <Card className="featured-game-card">
          <FeaturedGameCard
            game={fgame}
            startGame={startGame}
            date={gameStartDate}
          />
        </Card>
      </div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Επερχομενες εκπομπες" key="1">
          <List
            dataSource={sortedGames}
            renderItem={game => (
              isFeatured(game.game_id)
                ? null
                : (
                  <List.Item>
                    <GameItemCard
                      game={game}
                      startGame={startGame}
                    />
                  </List.Item>
                )
            )}
          />

        </TabPane>
        <TabPane tab="Προηγουμενες εκπομπες" key="2">
          <List
            dataSource={previousEpisodes}
            renderItem={game => (
              isFeatured(game.game_id)
                ? null
                : (
                  <List.Item>
                    <GameItemCard
                      game={game}
                      date={game.end_timestamp}
                      hasLeaderboard={gamesWithLB.indexOf(game.game_id) !== -1}
                    />
                  </List.Item>
                )
            )}
          />
        </TabPane>
      </Tabs>
    </section>
  );
}

GameList.propTypes = {
  history: PropTypes.object,
  games: PropTypes.array,
  nextEpisode: PropTypes.string,
  getState: PropTypes.func,
  getGames: PropTypes.func
};

function mapStateToProps(state) {
  return {
    games: state.games.list
  };
}

const mapDispatchToProps = (dispatch) => ({
  getState: () => dispatch(CpanelActions.getState()),
  getGames: () => dispatch(GamesActions.fetchGames())
});

export default connect(mapStateToProps, mapDispatchToProps)(GameList);
