import * as ActionTypes from '../actiontypes/index';
import * as UserCalls from '../../api/user';

/**
 * Requests all available games from the server.
 *
 * @returns {function(*): Promise<T>}
 */
export const loginUser = (userEmail) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.LOGIN_IN_PROGRESS
    });

    return UserCalls.loginUser(userEmail)
      .then(response => {
        if (response?.error) {
          dispatch({
            type: ActionTypes.LOGIN_FAIL,
            error: response.error
          });
          return Promise.reject(response.error[0]);
        }
        dispatch({
          type: ActionTypes.LOGIN_SUCCESS,
          results: response
        });
        return response;
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.LOGIN_FAIL,
          error
        });
        return Promise.reject(error);
      });
  };
};

/**
 * Requests all available games from the server.
 *
 * @returns {function(*): Promise<T>}
 */
export const authUser = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.AUTHENTICATE_IN_PROGRESS
    });

    return UserCalls.authUser(payload)
      .then(response => {
        if (response?.errors) {
          dispatch({
            type: ActionTypes.AUTHENTICATE_FAIL,
            error: response.errors
          });
          return Promise.reject(response.errors[0]);
        }
        localStorage.setItem('session_id', response.session_id);
        dispatch({
          type: ActionTypes.AUTHENTICATE_SUCCESS,
          results: response
        });
        return response;
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.AUTHENTICATE_FAIL,
          error
        });
        return Promise.reject(error);
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    let ls_session_id = localStorage.getItem('session_id');
    dispatch({
      type: ActionTypes.LOGOUT_IN_PROGRESS
    });
    if (ls_session_id == null && window.location.pathname !== '/login') {
      dispatch({
        type: ActionTypes.LOGOUT_SUCCESS,
        results: {}
      });
      localStorage.removeItem('session_id');
      localStorage.removeItem('email');
      window.location.pathname = '/login';
      return {};
    }
    return UserCalls.logoutUser()
      .then(response => {
        if (response?.errors) {
          dispatch({
            type: ActionTypes.LOGOUT_FAIL,
            error: response.errors
          });
          return Promise.reject(response.errors[0]);
        }
        dispatch({
          type: ActionTypes.LOGOUT_SUCCESS,
          results: response
        });
        localStorage.removeItem('session_id');
        localStorage.removeItem('email');
        window.location.pathname = '/login';
        return response;
      })
      .catch(error => {
        if (error.status === 400 || error.status === 401) {
          dispatch({
            type: ActionTypes.LOGOUT_SUCCESS
          });
          localStorage.removeItem('session_id');
          localStorage.removeItem('email');
          window.location.pathname = '/login';
          return error;
        }
        dispatch({
          type: ActionTypes.LOGOUT_FAIL,
          error
        });
        return Promise.reject(error);
      });
  };
}

export const getUser = (userEmail) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.GETUSER_IN_PROGRESS
    });

    return UserCalls.getUser(userEmail)
      .then(response => {
        if (response?.errors) {
          dispatch({
            type: ActionTypes.GETUSER_FAIL,
            error: response.errors
          });
          return Promise.reject(response.errors[0]);
        }
        dispatch({
          type: ActionTypes.GETUSER_SUCCESS,
          results: response
        });
        return response;
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.GETUSER_FAIL,
          error
        });
        return Promise.reject(error);
      });
  };
}
