export default function findMissingNumberInSeq (arrayParam = []) {
  if (!Array.isArray(arrayParam) || arrayParam.length === 0) {
    return '';
  }
  const missingNumbArray = arrayParam.reduce(function(acc, cur, ind, arr) {
    var diff = cur - arr[ind-1];
    if (diff > 1) {
      var i = 1;
      while (i < diff) {
        acc.push(arr[ind-1]+i);
        i++;
      }
    }
    return acc;
  }, []);

  return missingNumbArray;
}
