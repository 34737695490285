/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  message,
  Row,
  Col,
  Form,
  Input
} from 'antd';
import * as UserActions from '../../store/actions/user';
import 'url-search-params-polyfill';
import './Login.scss'

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
};
const tailLayout = {
  wrapperCol: { offset: 4, span: 20 }
};

const validateMessages = {
  required: 'Υποχρεωτικό πεδίο',
  types: {
    email: 'Παρακαλώ εισάγετε ένα σωστό email.'
  }
};

const Login = ({ history, loginUser, authUser, isLoading, isAuthenticated, isLoggedIn, logout }) => {
  const [loggedIn, setLoggedIn] = useState(false);

  const onFinish = values => {
    loginUser(values.email)
      .then((res) => {
        message.success('User Successfuly logged in.');
        localStorage.setItem('email', values.email);
      })
      .catch(err => {
        console.log(err);
        const errmessage = err?.description || 'Κάποιο σφάλμα προέκυψε, παρακαλώ δοκιμάστε ξανά.';
        message.error(errmessage);
      });
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    setLoggedIn(isLoggedIn);
  }, [isLoggedIn])

  return (
    <Row justify="center" align="middle">
      <Col lg={14} md={18} xs={24}>
        <Card>
          {!loggedIn
            ? (<Form
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              validateMessages={validateMessages}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, type: 'email' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <p>Θα σου στείλουμε ένα link με το οποίο θα ανακτήσεις αμέσως το λογαριασμό σου.</p>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>)
            : <>
              <p>Λάβατε ένα email με τον σύνδεσμο που πρέπει να ακολουθήσετε για να γίνει η αυθεντικοποίηση χρήστη</p>
              <p>Για να αποσταλεί ξανά, παρακαλώ πατήστε
                <a onClick={() => setLoggedIn(false)}> εδώ</a>
              </p>
            </>
          }
        </Card>
      </Col>
    </Row>
  )
};

Login.propTypes = {
  loginUser: PropTypes.func,
  authUser: PropTypes.func,
  isLoading: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  history: PropTypes.object,
  logout: PropTypes.func
};
//isUserLoading, isLoggedIn, isAuthenticated
const mapStateToProps = (state) => ({
  isLoading: state.user.loading,
  isLoggedIn: state.user.loggedin,
  isAuthenticated: state.user.authenticated
});

const mapDispatchToProps = (dispatch) => ({
  loginUser: (userEmail) => dispatch(UserActions.loginUser(userEmail)),
  authUser: (payload) => dispatch(UserActions.authUser(payload)),
  logout: () => dispatch(UserActions.logout())
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
