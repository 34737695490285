import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './Timer.scss';

const Timer = ({ seconds, onExpiration=() => {} }) => {
  const [rseconds, setRseconds] = useState(seconds);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (rseconds > 1) {
        setRseconds(rseconds - 1);
      }
      if (rseconds === 1) {
        clearInterval(myInterval);
        setRseconds(0);
        onExpiration();
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  return (
    <div className="progressbar-wrapper">
      <CircularProgressbar
        maxValue={seconds}
        minValue={0}
        value={rseconds}
        text={rseconds}
        counterClockwise={true}
        styles={{
          // Customize the text
          text: {
            // Text size
            fontSize: '40px'
          }
        }}
      />
    </div>
  );
}

Timer.propTypes = {
  seconds: PropTypes.number,
  onExpiration: PropTypes.func
};

export default Timer;
