import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Spinner = ({ isBlock }) => {

  const renderSpinner = () => {
    return (isBlock === true
      ? (
        <div className="text-center">
          <Spin indicator={antIcon} />
        </div>
      )
      : (<Spin indicator={antIcon} />)
    );
  }

  return (renderSpinner());
}

Spinner.propTypes = {
  isBlock: PropTypes.bool
};

export default Spinner;
