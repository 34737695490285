import _fetch from '../_fetch';

const { REACT_APP_API_URL } = process.env;

export default function getState(options = {}) {

  const url = `${REACT_APP_API_URL}/cpanel/`;
  const config = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    redirect: 'follow',
    ...options.reqConfig
  };
  return _fetch(url, {
    withAuth: true,
    ...options
  }, config);
}
