import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Tabs,
  Steps,
  Button,
  Card,
  message,
  Row,
  Col
} from 'antd';
import * as CpanelActions from '../../store/actions/cpanel';
import * as QuestionActions from '../../store/actions/questions';
import { getQuestion } from '../../store/reducers';
import ActiveGameHeader from '../../components/ActiveGameHeader';
import Timer from '../../components/Timer';
import GameNotStarted from '../../components/GameNotStarted';
import AnswersOptions from '../../components/AnswersOptions';
import Notification from '../../components/Notification';
import QuestionModal from '../../modals/QuestionModal';
import {
  WarningOutlined,
  SoundOutlined,
  CloseOutlined,
  CheckOutlined
} from '@ant-design/icons';
import './ActiveGame.scss';

const { TabPane } = Tabs;
const { Step } = Steps;

const GAME_STRUCTURE = {
  rounds: [
    {
      questionsLen: 10,
      maxQuestionIndex: 10
    },
    {
      questionsLen: 10,
      maxQuestionIndex: 20
    },
    {
      questionsLen: 5,
      maxQuestionIndex: 25
    }
  ]
};

const ActiveGame = props => {
  // function to calculate the round index at a 1-3 scale
  const calculateRound = () => {
    if (gameState === 'internal_start') {
      return 1;
    }
    const searchByQuestionsSeq = gameState === 'game_active' ? props.question_seq + 1 : props.question_seq;
    const curRoundIdx = GAME_STRUCTURE.rounds.findIndex(round => searchByQuestionsSeq <= round.maxQuestionIndex);
    if ((curRoundIdx + 1) !== currentRound) {
      setCurrentRound(curRoundIdx + 1)
    }
    return (curRoundIdx + 1) || 1;
  }

  // function to calculate the question index at a 1-5 scale
  const calculateQuestion = () => {
    if (props.question_seq == null) {
      return 1;
    }
    if (gameState === 'internal_start') {
      return 1;
    }
    let fetchNext = 0;
    if (gameState === 'idle' || gameState === 'game_active') {
      fetchNext = 1;
    }
    // Get the round that we currently are by question_seq
    const curRound = GAME_STRUCTURE.rounds.find(round => props.question_seq <= round.maxQuestionIndex) ?? GAME_STRUCTURE.rounds[0];
    return ((props.question_seq || 0) % curRound.questionsLen + fetchNext) || curRound.questionsLen;
  }

  /* STATE VARS DECLARATION */
  const gameParams = props.match.params;
  const [redAlertMode, setRedAlertMode] = useState(false);
  const [gameState, setGameState] = useState('game_not_started');
  const [timerLoading, setTimerLoading] = useState(false);
  const [shouldFetchNewQuestion, setShouldFetchNewQuestion] = useState(false);
  const [nextAnswers, setNextAnswers] = useState([]);
  const [nextQuestion, setNextQuestion] = useState('');
  const [desiredRound, setDesiredRound] = useState(null);
  const [desiredQuestion, setDesiredQuestion] = useState(null);
  const [qModalVisibility, setQModalVisibility] = useState(false);
  const [questionObj, setQuestionObj] = useState({});
  const [currentRound, setCurrentRound] = useState(1);
  const [notificationObj, setNotificationObj] = useState({
    visible: false,
    icon: null,
    title: '',
    subtitle: '',
    type: '',
    banner: '',
    extra: []
  });

  const switchInput = useRef();

  // const that keeps the game states
  // BE Game states: idle, presented_answers, accepting_answers, game_active
  // FE Game states: idle, presented_answers, accepting_answers, presenting_correct, presenting_correct, game_ended
  const GAME_STATES = {
    game_not_started: {
      btnTitle: 'Εναρξη παιχνιδιου',
      btnAction: 'internal_start'
    },
    internal_start: {
      step: 0,
      pageTitle: 'To παιχνίδι ξεκίνησε!',
      pageSubTitle: 'Πατήστε το κουμπί προβολή ερώτησης όταν ο παρουσιαστής ετοιμάσει το κοινό για την ερώτηση',
      btnTitle: 'Προβολη ερωτησης',
      btnAction: 'idle'
    },
    idle: {
      step: 1,
      pageTitle: `Οι χρήστες βλέπουν την ένδειξη του γύρου ${Math.floor(((props.question_seq || 1) - 1) / 5) + 1} ερώτηση ${calculateQuestion()}`,
      pageSubTitle: 'Πατήστε το κουμπί προβολή απαντήσεων όταν ο παρουσιαστής ετοιμάσει το κοινό για την ερώτηση ',
      btnTitle: 'Προβολη απαντησεων',
      btnAction: 'gameStart'
    },
    game_active: {
      step: 1,
      pageTitle: `Οι χρήστες βλέπουν την ένδειξη του γύρου ${calculateRound()} ερώτηση ${calculateQuestion()}`,
      pageSubTitle: 'Πατήστε το κουμπί προβολή απαντήσεων όταν ο παρουσιαστής ετοιμάσει το κοινό για την ερώτηση ',
      btnTitle: 'Προβολη απαντησεων',
      btnAction: 'presentAnswers'
    },
    presented_answers: {
      step: 2,
      pageTitle: `Οι χρήστες βλέπουν τις απαντήσεις του γύρου ${calculateRound()} ερώτηση ${calculateQuestion()}`,
      pageSubTitle: 'Πατήστε το κουμπί έναρξης χρόνου όταν ο παρουσιαστής ετοιμάσει το κοινό με την αντιστροφη μέτρηση',
      btnTitle: 'Εναρξη χρονου',
      btnAction: 'startTimer'
    },
    accepting_answers: {
      step: 3,
      pageTitle: 'Οι χρήστες απαντάνε στην ερώτηση',
      pageSubTitle: 'Παρακαλώ περιμένετε 5" μέχρι να τελειώσει ο χρονικός περιορισμός που απαιτείται για να απαντήσουν οι χρήστες',
      btnTitle: 'Εμφανιση αποτελεσματων',
      btnAction: 'presentAnswer'
    },
    presenting_correct: {
      step: 4,
      pageTitle: 'Οι χρήστες λαμβάνουν τα αποτελέσματα τους καθώς και τους πόντους που αντιστοιχούν',
      pageSubTitle: 'Παρακαλώ περιμένετε 10" μέχρι οι χρήστες να δουν τα αποτελέσματά τους και μετά μπορείτε να προχωρήσετε στην επόμενη ερώτηση',
      btnTitle: 'Επομενη ερωτηση',
      btnAction: 'questionEnd'
    },
    question_ended: {
      step: 5,
      pageTitle: `Η ερώτηση ${calculateQuestion()} από τον γύρο ${calculateRound()} τελείωσε!`,
      pageSubTitle: 'Πατήστε το κουμπί επόμενης ερώτησης όταν ο παρουσιαστής ετοιμάσει το κοινό με την επόμενη ερώτηση',
      btnTitle: 'Επομενη ερωτηση',
      btnAction: 'nextQuestion'
    },
    game_ended: {
      step: 5,
      pageTitle: 'Το παιχνίδι τελείωσε!',
      pageSubTitle: 'Πατήστε το κουμπί "Επιβεβαίωση Επόμενου Επεισοδίου" για να ετοιμαστεί το επόμενο επεισόδιο στο διαχειριστικό σύστημα',
      btnTitle: 'ΗΜΕΡΟΜΗΝΙΑ ΕΠΟΜΕΝΟΥ ΕΠΕΙΣΟΔΙΟΥ',
      btnAction: 'setNextGame'
    }
  };

  //Set game state to 'game_ended' if we reached 25 questions and 'question_ended' otherwise
  const onExpiration = () => {
    setTimerLoading(false);
    if (gameState === 'presenting_correct') {
      if (props.question_seq === 25) {
        setGameState('game_ended');
      } else {
        setGameState('question_ended');
      }
    }
  }

  // Set Red Alert on or off
  const onSwitchChange = (checked) => {
    checked ? setRedAlertMode(true) : setRedAlertMode(false);
  }

  /**
   * Game action Handler
   * @argument {String} action the intended action we want to do
  */
  const handleGameAction = (action) => {
    switch (action) {
      case 'game_not_started':
        //Let's go to the next state manually at this time.
        setGameState('internal_start');
        break;
      case 'internal_start':
        //Let's go to the next state manually at this time.
        setGameState('internal_start');
        // triggerAction('startEpisode');
        break;
      case 'idle':
        //Let's go to the next state manually at this time.
        triggerAction('startEpisode', gameParams.id);
        break;
      case 'presentAnswers': {
        let questionSeq = 1;
        if (props.question_seq != null) {
          questionSeq = props.question_seq + 1;
        }
        setNextQuestion(props.getQuestion(questionSeq).question);
        triggerAction('presentAnswers', { game_id: gameParams.id, question_seq: questionSeq });
        break;
      }
      case 'startTimer':
        triggerAction('startAnswering');
        break;
      case 'presentAnswer':
        triggerAction('presentAnswer');
        break;
      case 'questionEnd':
        if (props.question_seq === 25) {
          setGameState('game_ended');
        } else {
          setGameState('question_ended');
        }
        break;
      case 'nextQuestion':
        if (props.question_seq === 25) {
          setGameState('game_ended');
        } else {
          setGameState('game_active');
          setShouldFetchNewQuestion(true);
        }
        break;
      case 'setNextGame':
        props.history.push('/set-next-episode');
        break;
      default:
        return false;
    }
  }

  const triggerAction = (action, payload) => {
    props[action](payload)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // Update UI to display Ads disclaimer
  const renderAdsDisclaimer = () => {
    document.body.classList.remove('error-enabled');
    document.body.classList.add('adbreak-enabled');
    setNotificationObj({
      visible: true,
      icon: <SoundOutlined />,
      title: 'Είσαστε σε κατάσταση αναμονής λόγω προγραμματισμένων διαφημίσεων. Πατήστε το κουμπί λήξη διαφημίσεων όταν η εκπομπή ξανα αρχίσει',
      subtitle: 'Οι χρήστες του παιχνιδιού βρίσκονται σε κατάσταση αναμονής μέχρι να τελειώσουν οι διαφημισεις',
      type: 'warning',
      banner: 'Διαλειμα διαφημισεων',
      extra: [
        <Button
          key="1"
          type="primary"
          icon={<CheckOutlined />}
          onClick={() => triggerExitRedAlert()}
        >
          Ληξη διαφημισεων
        </Button>
      ]
    });
  }

  // Function to enter the ads mode (while in RedAlert mode)
  const triggerAdBreak = () => {
    props.setOverlay({ type: 'ads' })
      .catch(err => {
        console.log(err);
        message.error('Κάτι πήγε στραβά. Παρακαλώ δοκιμάστε ξανά');
      });

  }

  //Reset
  const resetNotificationObg = () => {
    setNotificationObj({
      visible: false,
      icon: null,
      title: '',
      subtitle: '',
      type: '',
      banner: '',
      extra: []
    });
  }

  // Function to exit the RedAlert mode.
  // Set the overlay to none and then reset the respective state vars and DOM elements
  const triggerExitRedAlert = () => {
    props.setOverlay({ type: 'none' })
      .then(() => {
        document.body.classList.remove('adbreak-enabled');
        document.body.classList.remove('error-enabled');
        resetNotificationObg();
        switchInput.current.click();
      })
      .catch(err => {
        console.log(err);
        message.error('Κάτι πήγε στραβά. Παρακαλώ δοκιμάστε ξανά');
      })

  }

  // Update UI to show Error disclaimer
  const renderErrDisclaimer = () => {
    document.body.classList.remove('adbreak-enabled');
    document.body.classList.add('error-enabled');
    setNotificationObj({
      visible: true,
      icon: <WarningOutlined />,
      title: 'Βρίσκεστε σε κατάσταση όπου μπορείτε να μεταβείτε σε διαφορετικό γύρο και ερώτηση σε περίπτωση μη συγχρονισμού της εκπομπής με τις ερωτήσεις',
      subtitle: 'Οι χρήστες του παιχνιδιού βρίσκονται σε κατάσταση αναμονής μέχρι να τελειώσετε την ενέργεια',
      type: 'error',
      banner: 'Μεταβαση σε γυρο / ερωτηση',
      extra: [
        <Button
          key="1"
          icon={<CloseOutlined />}
          className="mr-2"
          onClick={() => triggerExitRedAlert()}
        >
          Ακυρωση
        </Button>
      ]
    });
  }
  // Function to enter the error mode (while in RedAlert mode)
  const triggerErrorScreen = () => {
    props.setOverlay({ type: 'error' })
      .catch(err => {
        console.log(err);
        message.error('Κάτι πήγε στραβά. Παρακαλώ δοκιμάστε ξανά');
      })
  }

  const fetchNextQuestionAnswers = () => {
    const { questions, question_seq } = props;
    const qSec = (question_seq + 1) || 1;
    if (questions == null || questions.length === 0) {
      return false;
    } else if (question_seq === 25) {
      setGameState('game_ended');
      return false;
    }

    let quest = questions.find(question => {
      return question.question_seq === (qSec);
    });
    setNextAnswers(quest.answers);
    setNextQuestion(props.getQuestion(qSec).question);
  }

  const roundChangeTrigger = (k, e) => {
    //Init the question number as we just changed desired round
    setDesiredQuestion(null);
    setDesiredRound(parseInt(k));
  }

  const questionChangeTrigger = (k, e) => {
    setDesiredQuestion(parseInt(k));
  }

  const handleQModalOk = () => {
    // triger next question to be the desired one.
    triggerExitRedAlert();
    triggerAction('presentAnswers', { game_id: gameParams.id, question_seq: questionObj.question_seq });
    setDesiredRound(null);
    setDesiredQuestion(null);
    setQModalVisibility(false);
    setQuestionObj({});
  }
  const handleQModalCancel = () => {
    setDesiredRound(null);
    setDesiredQuestion(null);
    setQModalVisibility(false);
    setQuestionObj({});
  }

  useEffect(() => {
    // Question ended let's fetch the next one.
    if (props.cpanelState === 'game_active' && gameState === 'game_active') {
      fetchNextQuestionAnswers();
    }

    if (gameState === 'presented_answers') {
      setNextQuestion(props.getQuestion(props.cpanel?.cpanelQS)?.question);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState, props.cpanelState, props.questions]);

  useEffect(() => {
    // Use the cpanelState
    if (props.cpanelState != null
      && props.cpanelState !== 'idle'
      && gameState !== 'question_ended'
      && gameState !== 'game_ended'
    ) {
      setGameState(props.cpanelState);
    }

    // Handle 'go to specific question' trigger from the admin
    // when the current state is question_ended or game_ended
    if (props.cpanelState === 'presented_answers'
      && (gameState === 'question_ended' || gameState === 'game_ended')
    ) {
      setGameState(props.cpanelState);
    }

    // Start the timers when we are accepting answers or presenting the correct answer
    if (props.cpanelState === 'accepting_answers' || gameState === 'presenting_correct') {
      setTimerLoading(true);
    }

    // Set state to presenting correct when appropriate
    if (props.cpanelState === 'game_active'
      && props.answers != null
      && (gameState !== 'question_ended'
        && gameState !== 'game_ended'
        && gameState !== 'game_active')
    ) {
      setGameState('presenting_correct');
      // We just finished with a question and we should go to the next one.
      // setShouldFetchNewQuestion(true);
    } else {
      setShouldFetchNewQuestion(false);
    }
  }, [props.cpanelState, props.answers, gameState]);

  // Handle the change of the overlay_type
  useEffect(() => {
    let overlayType = props.cpanel?.overlay_type;
    let isSwitchOn = switchInput.current.getAttribute('aria-checked');
    if (overlayType !== '') {
      switch (overlayType) {
        case 'error':
          if (isSwitchOn === 'false') {
            switchInput.current.click();
          }
          // triggerErrorScreen();
          renderErrDisclaimer();
          break;
        case 'ads':
          if (isSwitchOn === 'false') {
            switchInput.current.click();
          }
          // triggerAdBreak();
          renderAdsDisclaimer();
          break;
        default:
          return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cpanel.overlay_type]);

  useEffect(() => {
    props.getState()
      .catch((err) => {
        console.log(err);
        message.error('Κάτι πήγε στραβά. Δοκιμάστε ξανά.');
      });
    props.getQuestions(gameParams.id)
      .catch(err => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (desiredQuestion != null && desiredRound != null) {
      // We multiply with 10 as the first 2 rounds have 10 questions each and we don't care about the last one.
      let questionIndx = ((desiredRound - 1) * 10 + desiredQuestion);
      setQModalVisibility(true);
      setQuestionObj(props.getQuestion(questionIndx));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desiredQuestion, desiredRound]);

  const renderRoundTabs = () => {
    return [...GAME_STRUCTURE.rounds].map((round, idx) => (
      <TabPane className={`${desiredRound === 1 ? 'is-desired' : ''}`} tab={`${idx + 1}ος ΓΥΡΟΣ`} key={`${idx + 1}`} disabled={!redAlertMode || notificationObj.type !== 'error'} />
    ));
    // <TabPane className={`${desiredRound === 1 ? 'is-desired' : ''}`} tab="1ος ΓΥΡΟΣ" key="1" disabled={!redAlertMode || notificationObj.type !== 'error'} />
  };

  /**
   * Function to make the rendering of the tabs more dynamic.
   * It reads the length of the current round using the const declared at the top of this file (gameStructure)
   * Thus, by updating the specified const we can change the number of the questions for each round.
  */
  const renderQuestionsTabs = () => {
    const tabsLen = GAME_STRUCTURE.rounds[(desiredRound || currentRound || 1) - 1]?.questionsLen || 0;
    // setQuestionTabsLength(GAME_STRUCTURE.rounds[calculateRound() - 1].questionsLen);
    return [...Array(tabsLen)].map((i, n) => (
      <TabPane tab={`ΕΡ. ${n+1}`} key={n+1} disabled={!redAlertMode || notificationObj.type !== 'error' || desiredRound == null} />
    ));
  }

  const renderGameComponents = () => {
    switch (gameState) {
      case 'game_not_started':
        return <GameNotStarted />;
      case 'game_ended':
        return (<Row>
          <Col span={16} offset={3} className="text-center">
            <h1 className="mb-5">{GAME_STATES[gameState].pageTitle}</h1>
            <h2 className="mb-5">{GAME_STATES[gameState].pageSubTitle}</h2>
          </Col>
        </Row>);
      default:
        return (<>
          {notificationObj.type === 'error' ? <p className="error-warning"><WarningOutlined />Για να μεταβείτε σε συγκεκριμένη ερώτηση, επιλέξτε πρώτα γύρο και στη συνέχεια ερώτηση.</p> : null}
          <Tabs
            type="card"
            defaultActiveKey="1"
            activeKey={calculateRound().toString()}
            onTabClick={(k, e) => roundChangeTrigger(k, e)}
            onChange={(ak) => console.log(ak)}
          >
            {renderRoundTabs()}
          </Tabs>
          <Tabs
            activeKey={calculateQuestion().toString()}
            onTabClick={(k, e) => questionChangeTrigger(k, e)}
            onChange={(ak) => console.log(ak)}
          >
            {renderQuestionsTabs()}
          </Tabs>
          <main style={{ padding: '25px' }}>
            {GAME_STATES[gameState] != null
              ? <Row>
                <Col span={4}>
                  <div className="game-main">
                    <Steps
                      progressDot
                      direction="vertical"
                      size="small"
                      current={GAME_STATES[gameState].step}
                    >
                      <Step title="Φορτωση παιχνιδιου" />
                      <Step title="Εναρξη ερωτησης" />
                      <Step title="Προβολη απαντησεων" />
                      <Step title="Εναρξη χρονου" />
                      <Step title="Εμφανιση αποτελεσματων" />
                      <Step title="Τελος ερωτησης" />
                    </Steps>
                  </div>
                </Col>
                <Col span={18} offset={1} className="text-center">
                  <h1>{GAME_STATES[gameState].pageTitle}</h1>
                  <h2>{GAME_STATES[gameState].pageSubTitle}</h2>
                  {gameState === 'accepting_answers' ? <Timer seconds={5} onExpiration={() => onExpiration()} /> : null}
                  {gameState === 'presenting_correct' ? <Timer seconds={5} onExpiration={() => onExpiration()} /> : null}
                  {
                    gameState === 'game_active' && nextAnswers.length > 0
                      ? (
                        <Row className="mb-5">
                          <Col span={20} offset={2}>
                            <h4>Παρακάτω μπορείτε να επιβεβαιώσετε την επερχόμενη ερώτηση απαντήσεις</h4>
                            <p>{nextQuestion}</p>
                            <div className="answers-wrapper">
                              <AnswersOptions answers={nextAnswers} inactive={true} />
                            </div>
                          </Col>
                        </Row>
                      )
                      : null
                  }
                  {
                    (shouldFetchNewQuestion === false && (gameState === 'presented_answers'))
                      ? (
                        <Row className="mb-5">
                          <Col span={20} offset={2}>
                            <h4>Παρακάτω μπορείτε να επιβεβαιώσετε τις επερχόμενες απαντήσεις</h4>
                            <p>{nextQuestion}</p>
                            <div className="answers-wrapper">
                              <AnswersOptions answers={props.answers} />
                            </div>
                          </Col>
                        </Row>
                      )
                      : null
                  }
                </Col>
              </Row>
              : null
            }
          </main>
        </>);
    }
  }

  return (
    <section>
      <ActiveGameHeader
        gameId={gameParams.id}
        adsButtonClick={triggerAdBreak}
        errorButtonClick={triggerErrorScreen}
        onSwitchChange={onSwitchChange}
        redAlertMode={redAlertMode}
        setSwitchRef={switchInput}
      />
      <Card>
        {renderGameComponents()}

        {!notificationObj.visible
          ? <Button
            className="active-game-btn"
            block
            shape="round"
            type="primary"
            htmlType="button"
            loading={props.cpanel?.loading || timerLoading}
            onClick={() => handleGameAction(GAME_STATES[gameState].btnAction)}
          >
            {GAME_STATES[gameState]?.btnTitle}
          </Button>
          : null
        }
      </Card>

      <QuestionModal
        questionObj={questionObj}
        visible={qModalVisibility}
        handleOk={() => handleQModalOk()}
        handleCancel={() => handleQModalCancel()}
      />
      <Notification
        visible={notificationObj.visible}
        type={notificationObj.type}
        title={notificationObj.title}
        subtitle={notificationObj.subtitle}
        icon={notificationObj.icon}
        banner={notificationObj.banner}
        extra={notificationObj.extra}
      />
    </section >
  );
}

ActiveGame.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  history: PropTypes.object,
  questions: PropTypes.array,
  fetchQuestions: PropTypes.func,
  getState: PropTypes.func,
  presentAnswers: PropTypes.func,
  startAnswering: PropTypes.func,
  presentAnswer: PropTypes.func,
  question_seq: PropTypes.number,
  cpanelState: PropTypes.string,
  answers: PropTypes.array,
  cpanel: PropTypes.object,
  games: PropTypes.array,
  getQuestions: PropTypes.func,
  getQuestion: PropTypes.func,
  setOverlay: PropTypes.func
};

const mapStateToProps = state => ({
  cpanelState: state.cpanel.state,
  question_seq: state.cpanel.question_seq,
  answers: state.cpanel.answers,
  cpanel: state.cpanel,
  games: state.games.list,
  questions: state.questions.list,
  getQuestion: question_seq => getQuestion(state, question_seq)
})

const mapDispatchToProps = (dispatch) => ({
  getState: () => dispatch(CpanelActions.getState()),
  startEpisode: (gameId) => dispatch(CpanelActions.startEpisode(gameId)),
  presentAnswers: (payload) => dispatch(CpanelActions.presentAnswers(payload)),
  startAnswering: () => dispatch(CpanelActions.startAnswering()),
  presentAnswer: () => dispatch(CpanelActions.presentAnswer()),
  setNextEpisode: (payload) => dispatch(CpanelActions.setNextEpisode(payload)),
  getQuestions: (gameId) => dispatch(QuestionActions.fetchQuestions(gameId)),
  setOverlay: (data) => dispatch(CpanelActions.setOverlay(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveGame);
