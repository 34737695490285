import 'url-search-params-polyfill';
import store from '../store/store';
import { logout } from '../store/actions/user';

/**
 * Create requests to fetch or send resources to the server using the `Fetch` API.
 *
 * IMPORTANT NOTE:
 * For file uploads use the `_xhr.js` which utilizes the `XMLHttpRequest (XHR)` object and allows to abort requests and track upload progress.
 * Use this one for any other type of requests.
 *
 * @param {String} url API endpoint
 * @param {Object} [userOptions={}]
 * @param {Boolean} [userOptions.withAuth=true] Denotes if should add auth header or not.
 * @param {String} [userOptions.responseType="json"] Rsponse type; tha way to handle the response. Avialbale values: `arrayBuffer`, `blob`, `json`, `text`, `formData`.
 * @param {Object} fetchConfig Required cinfiguration for the `Fetch` API; eg `fetchConfig.method`, `fetchConfig.body`, etc...
 */
function _fetch(url, userOptions = {}, fetchConfig = {}) {
  const defaultUserOptions = {
    withAuth: true,
    responseType: 'json'
  };

  userOptions = {
    ...defaultUserOptions,
    ...userOptions
  };

  fetchConfig.headers = fetchConfig.headers || {};

  if (userOptions.withAuth) {
    url = new URL(url);
    url.searchParams.append('session_id', (localStorage.getItem('session_id') || ''));
  }

  console.log('Start fetching!');
  return fetch(url, fetchConfig).then(response => {
    const defaultErrorDescription = 'An error occurred with the server.';
    let returnable;

    console.log(response);
    if (response.ok) {
      returnable = response[userOptions.responseType]().catch(() => { });
    } else {
      returnable = response;
      if (response.status === 401) {
        //context.eventEmitter.publish(USER_UNAUTHORIZED, response);
        store.dispatch(logout());
        return Promise.reject({
          error: true,
          status: response.status,
          code: response.code || response.status,
          description: defaultErrorDescription,
          meta: response.meta
        });
      }

      returnable = response.json().then(json => {
        let err = json.error || json.errors[0] || json;
        return Promise.reject({
          error: true,
          status: response.status,
          code: err.code || err.status,
          description: err.message || defaultErrorDescription,
          meta: err.meta
        });
      }, error => {
        return Promise.reject({
          error,
          status: response.status,
          code: response.status,
          description: defaultErrorDescription,
          meta: {}
        });
      });
    }

    return returnable;
  }).catch(err => Promise.reject(err));
}

export default _fetch;
