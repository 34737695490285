import * as ActionTypes from '../actiontypes/index';

export const initialState = {
  state: '',
  game_id:'',
  loading: false,
  question_seq: 0,
  answers: null,
  overlay_type: '',
  error: ''
};

function games(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_STATE_SUCCESS:
      return {
        ...state,
        state: action.results.state,
        game_id: action.results.game_id,
        question_seq: action.results.question_seq,
        answers: action.results.answers,
        overlay_type: action.results.overlay_type,
        loading: false,
        error: ''
      };
    case ActionTypes.GET_STATE_IN_PROGRESS:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.GET_STATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case ActionTypes.START_EPISODE_SUCCESS:
      return {
        ...state,
        state: action.results.state,
        game_id: action.results.game_id,
        question_seq: action.results.question_seq,
        answers: action.results.answers,
        overlay_type: action.results.overlay_type,
        loading: false,
        error: ''
      };
    case ActionTypes.START_EPISODE_IN_PROGRESS:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.START_EPISODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case ActionTypes.PRESENT_ANSWERS_SUCCESS:
      return {
        state: action.results.state,
        game_id: action.results.game_id,
        question_seq: action.results.question_seq,
        answers: action.results.answers,
        overlay_type: action.results.overlay_type,
        loading: false
      };
    case ActionTypes.PRESENT_ANSWERS_IN_PROGRESS:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.PRESENT_ANSWERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case ActionTypes.PRESENT_ANSWER_SUCCESS:
      return {
        state: action.results.state,
        game_id:action.results.game_id,
        question_seq: action.results.question_seq,
        answers: action.results.answers,
        overlay_type: action.results.overlay_type,
        loading: false
      };
    case ActionTypes.PRESENT_ANSWER_IN_PROGRESS:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.PRESENT_ANSWER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case ActionTypes.START_ANSWERING_IN_PROGRESS:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.START_ANSWERING_SUCCESS:
      return {
        ...state,
        state: action.results.state,
        game_id: action.results.game_id,
        overlay_type: action.results.overlay_type,
        loading: false
      };
    case ActionTypes.START_ANSWERING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case ActionTypes.SET_NEXT_EPISODE_IN_PROGRESS:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.SET_NEXT_EPISODE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case ActionTypes.SET_NEXT_EPISODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case ActionTypes.SET_OVERLAY_IN_PROGRESS:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.SET_OVERLAY_SUCCESS:
      return {
        ...state,
        overlay_type: action.results.overlay_type,
        loading: false
      };
    case ActionTypes.SET_OVERLAY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return {...state};
  }
}

export const selectors = {
  getState: state => state.state,
  isStateLoading: state => state.loading,
  getActiveQuestion: state => state.question_seq,
  getActiveAnswers: state => state.answers
};

export default games;
