import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Card,
  Row,
  Col,
  Form,
  Select,
  DatePicker,
  Button,
  TimePicker,
  message
} from 'antd';
import * as CpanelActions from '../../store/actions/cpanel';
import * as GamesActions from '../../store/actions/games';
const { REACT_APP_ENV } = process.env;
const { Option } = Select;

// Validation messages for Question form
const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: 'Το πεδίο είναι υποχρεωτικό!'
};

const NextEpisodeForm = ({ history, games, setNextEpisode, getGames, match }) => {
  const gameParams = match.params;

  const [loading, setLoading] = useState(false);

  const getAvailableGames = () => {
    return REACT_APP_ENV === 'production'
      ? games.filter(game => game.end_timestamp == null)
      : games
  }

  const renderGameOptions = () => {

    const availableGames = getAvailableGames();
    const sortedGames = availableGames.sort((a, b) => parseInt(a.game_id) > parseInt(b.game_id) ? 1 : -1);
    return sortedGames.map((game) => {
      return <Option key={game.game_id.toString()} value={parseInt(game.game_id)}>Επεισόδιο {game.game_id}</Option>
    })
  }

  /**
   * Function that will executed when the form will be submitted.
   * @param values {Object} form input values
  */
  const onFormSubmit = values => {
    setLoading(true);
    //moment js expects MM-DD-YYY
    let date = moment(values.present_date).format('MM-DD-YYYY');
    let time = moment(values.present_time).format('HH:mm');
    let presentDateTime = (date + ' ' + time);
    const timestamp = moment(presentDateTime, 'MM-DD-YYYY HH:mm').unix();
    const payload = {
      game_id: values.game_id,
      timestamp
    };

    setNextEpisode(payload)
      .then(res => {
        message.success('Το επόμενο επεισόδιο επιβεβαιώθηκε επιτυχώς!');
        history.push('/');
      })
      .catch(err => {
        console.log(err);
        message.error('Κάποιο σφάλμα προέκυψε. Παρακαλώ προσπαθήστε ξανά.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const disabledDate = (current) => {
    return current && current < moment().subtract(1, 'days');
  }

  useEffect(() => {
    getGames()
      .catch(err => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <Row>
        <Col span={12} offset={6} className="text-center">
          <h1>Ημερομηνία επόμενου επεισοδίου</h1>
          <p>Πατήστε το κουμπί &quot;Επιβεβαίωση Επόμενου Επεισοδίου&quot; για να ετοιμαστεί το επόμενο επεισόδιο στο διαχειριστικό σύστημα</p>
        </Col>
      </Row>
      <Row>
        <Col span={12} offset={6}>
          <Form
            layout="horizontal"
            name="game-form"
            validateMessages={validateMessages}
            onFinish={onFormSubmit}
            initialValues={{
              'game_id': parseInt(gameParams.id) || null
            }}
          >
            <Form.Item
              name={['game_id']}
              label="Επόμενο επεισόδιο"
              rules={[{ required: true }]}
            >
              <Select>
                {renderGameOptions()}
              </Select>
            </Form.Item>
            <Form.Item
              name={['present_date']}
              label="Ημερομηνία προβολής"
              rules={[{ required: true }]}
            >
              <DatePicker
                format="DD-MM-YYYY"
                disabledDate={disabledDate}
                showTime={false}
              />
            </Form.Item>

            <Form.Item
              name={['present_time']}
              label="Ώρα προβολής"
              rules={[{ required: true }]}
            >
              <TimePicker
                format="HH:mm"
                showNow={false}
              />
            </Form.Item>

            <Button
              block
              shape="round"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              ΕΠΙΒΕΒΑΙΩΣΗ ΕΠΟΜΕΝΟΥ ΕΠΕΙΣΟΔΙΟΥ
            </Button>
          </Form>
        </Col>
      </Row>
    </Card>
  )
}

NextEpisodeForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  history: PropTypes.object,
  games: PropTypes.array,
  setNextEpisode: PropTypes.func,
  getGames: PropTypes.func
}

function mapStateToProps(state) {
  return {
    games: state.games.list
  };
}

const mapDispatchToProps = (dispatch) => ({
  setNextEpisode: (payload) => dispatch(CpanelActions.setNextEpisode(payload)),
  getGames: () => dispatch(GamesActions.fetchGames())
});

export default connect(mapStateToProps, mapDispatchToProps)(NextEpisodeForm);
