import React from 'react';
import PropTypes from 'prop-types';
import './QuestionModal.scss';
import {
  Modal,
  Button
} from 'antd';
import AnswersOptions from '../../components/AnswersOptions';

const QuestionModal = (props) => {
  const { visible, handleOk, handleCancel, questionObj } = props;
  return (
    <Modal
      title="Ερώτηση"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Ακύρωση
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Πήγαινε στην ερώτηση
        </Button>
      ]}
    >
      <p>{questionObj.question}</p>
      <AnswersOptions answers={questionObj.answers} inactive={false} />
      <div className="clear"></div>
    </Modal>
  )
}

QuestionModal.propTypes = {
  questionObj: PropTypes.object,
  visible: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func
}

export default QuestionModal;
