import _fetch from '../_fetch';
import 'url-search-params-polyfill';

const { REACT_APP_API_URL } = process.env;

export default function startEpisode(gameId, options = {}) {
  if (gameId == null) {
    throw new Error('Παρακαλώ προσθέστε game id');
  }
  let urlencoded = new URLSearchParams();
  urlencoded.append('cmd', 'start_episode');
  urlencoded.append('game_id', gameId);

  const url = `${REACT_APP_API_URL}/cpanel/`;
  const config = {
    method: 'POST',
    body: urlencoded,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    redirect: 'follow',
    ...options.reqConfig
  };
  return _fetch(url, {
    withAuth: true,
    ...options
  }, config);
}
