import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  PageHeader,
  Form,
  Button,
  Card,
  message
} from 'antd';
import Avatar from '../../components/Avatar';
import * as GamesActions from '../../store/actions/games';
import findMissingNumberInSeq from '../../utils/findMissingNumberInSeq';
import './GameForm.scss';

// Validation messages for Question form
const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: 'Το πεδίο είναι υποχρεωτικό!'
};

const GameForm = props => {
  // State declarations
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState(null);
  const [episodeId, setEpisodeId] = useState('');

  /**
   * Function that will executed when the form will be submitted.
   * @param values {Object} form input values
  */
  const onFormSubmit = values => {
    let formdata = new FormData();
    formdata.append('game_id', episodeId);
    formdata.append('file', fileList.originFileObj);
    setLoading(true);
    props.saveGame(formdata)
      .then(res => {
        if (res && res.error) {
          message.error(res.message);
        } else {
          message.success('To επεισόδιο δημιουργήθηκε επιτυχώς!')
          props.history.push(`/game/${episodeId}`);
        }
      })
      .catch(err => {
        console.log(err);
        message.error('Προέκυψε σφάλμα. Παρακαλώ δοκιμάστε ξανά.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setNewEpisodeId = () => {
    let gamesSeqArray = props.games.map(game => {
      return parseInt(game.game_id)
    });
    // TODO review sorting here.
    gamesSeqArray = gamesSeqArray.sort((a, b) => a > b ? 1 : -1);
    // gamesSeqArray = gamesSeqArray.sort();
    let missingSeq = null;
    if (gamesSeqArray[0] !== 1) {
      missingSeq = 1; //  If we are missing number 1 we need to explicitly add it here.
    } else {
      //Get the missing sequense number of the questions
      missingSeq = findMissingNumberInSeq(gamesSeqArray)[0] || (gamesSeqArray.length + 1);
    }
    setEpisodeId(missingSeq.toString());
  }

  const onLoaded = (file) => {
    setFileList(file);
  }

  // 'Component did update' like useEffect hook
  useEffect(() => {
    setNewEpisodeId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.games]);

  useEffect(() => {
    props.getGames()
      .catch(err => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        title={`Δημιουργία νέου επεισοδίου: Επεισόδιο ${episodeId}`}
        validateMessages={validateMessages}
      >
      </PageHeader>
      <Card>
        <Form
          layout="vertical"
          name="game-form"
          onFinish={onFormSubmit}
        >
          <Form.Item
            name={['thumbnail']}
            label={'Εισάγετε thumbnail της εκομπής'}
          >
            <Avatar onLoaded={onLoaded} />
          </Form.Item>
          <Form.Item>
            <Button
              className="mr-2"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Fragment >
  );
}

GameForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  saveGame: PropTypes.func,
  games: PropTypes.array,
  getGames: PropTypes.func
};

function mapStateToProps(state) {
  return {
    games: state.games.list
  };
}

const mapDispatchToProps = (dispatch) => ({
  saveGame: (payload) => dispatch(GamesActions.saveGame(payload)),
  getGames: () => dispatch(GamesActions.fetchGames())
});

export default connect(mapStateToProps, mapDispatchToProps)(GameForm);
