import _fetch from '../_fetch';
const { REACT_APP_API_URL } = process.env;

export default function fetchGames(options = {}) {
  const url = `${REACT_APP_API_URL}/games`;

  const config = {
    method: 'GET',
    headers: {
      Accept: 'application/json;charset=utf-8'
    },
    ...options.reqConfig
  };

  return _fetch(url, {
    withAuth: true,
    ...options
  }, config);
}
