import _fetch from '../_fetch';
import makeUrlSearchParams from '../utils/makeUrlSearchParams';

const { REACT_APP_API_URL } = process.env;

export default function authUser(payload, options = {}) {
  let urlencoded = makeUrlSearchParams(payload);

  const url = `${REACT_APP_API_URL}/users/auth`;
  const config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: urlencoded,
    redirect: 'follow',
    ...options.reqConfig
  };
  return _fetch(url, {
    withAuth: false, // TODO change to withAuth -> true
    ...options
  }, config);
}
