import _fetch from '../_fetch';

const { REACT_APP_API_URL } = process.env;

export default function saveGame(data, options = {}) {

  const url = `${REACT_APP_API_URL}/games`;
  const config = {
    method: 'PUT',
    body: data,
    headers: {},
    ...options.reqConfig
  };
  return _fetch(url, {
    withAuth: true,
    ...options
  }, config);
}
