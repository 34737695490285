import * as ActionTypes from '../actiontypes/index';

export const initialState = {
  loggedin: false,
  authenticated: false,
  loading: false,
  session_id: '',
  email: '',
  nickname: '',
  error: ''
};

function user(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOGIN_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loggedin: true,
        loading: false
      };
    case ActionTypes.LOGIN_FAIL:
      return {
        ...state,
        loggedin: false,
        loading: false,
        error: action.error
      };
    case ActionTypes.AUTHENTICATE_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case ActionTypes.AUTHENTICATE_SUCCESS:
      return {
        ...state,
        authenticated: true,
        loading: false,
        session_id: action.results.session_id
      };
    case ActionTypes.AUTHENTICATE_FAIL:
      return {
        ...state,
        authenticated:false,
        loading: false,
        error: action.error
      };
    case ActionTypes.LOGOUT_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case ActionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        loggedin: false,
        authenticated: false,
        session_id: '',
        loading: false,
        email: '',
        nickname: '',
        error: ''
      };
    case ActionTypes.LOGOUT_FAIL:
      return {
        ...state,
        loggedin: false,
        authenticated: false,
        session_id: '',
        loading: false,
        email: '',
        nickname: '',
        error: action.error
      };
    case ActionTypes.GETUSER_IN_PROGRESS:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case ActionTypes.GETUSER_SUCCESS:
      return {
        ...state,
        email: action.results.email,
        nickname: action.results.nickname,
        loading: false,
        error: ''
      };
    case ActionTypes.GETUSER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return { ...state };
  }
}

export const selectors = {};

export default user;
