/**
 * Function that returns the remaining time from now till a future moment.
 * @param date {number} the future moment
 * @return {String} A formatted string that contains days, hours, minutes and seconds till the
 */
export default function calculateRemainingTime (date = 0) {
  if (date === 0) {
    return false;
  }
  // Date now
  const date_now = new Date();
  let seconds = Math.floor((date - (date_now))/1000);
  let minutes = Math.floor(seconds/60);
  let hours = Math.floor(minutes/60);
  let days = Math.floor(hours/24);

  hours = hours-(days*24);
  minutes = minutes-(days*24*60)-(hours*60);
  seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

  return `${days}d ${hours}h ${minutes}' ${seconds}"`;
}
