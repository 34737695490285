import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import calculateRemainingTime from '../../utils/calculateRemainingTime';
import './Countdown.scss';

const Countdown = ({ timestamp = 0 }) => {
  const game_date = new Date(timestamp * 1000);
  const [timer, setTimer] = useState(null);
  const [remTime, setRemTime] = useState(calculateRemainingTime(game_date));

  const _isMounted = useRef(true);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    }
  }, []);

  useEffect(() => {
    if (timestamp !== 0 && _isMounted.current) {
      setTimer(setInterval(() => {
        _isMounted.current && setRemTime(calculateRemainingTime(game_date));
      }, 1000));
    }
    return () => clearInterval(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>{remTime}</>
  )
}

Countdown.propTypes = {
  timestamp: PropTypes.number
};

export default Countdown;
