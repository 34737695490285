import React from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown, Menu, List, Row, Col, Button } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import './Question.scss';

const ANSWERS_INDEXES = ['A', 'B', 'C', 'D'];

const Question = ({ question, triggerEditQuestion, delQuestion }) => {
  const questionActions = (
    <Menu>
      <Menu.Item>
        <Button
          block={true}
          type="primary"
          htmlType="button"
          onClick={(e) => {
            triggerEditQuestion(e, question.question_seq)
          }}
        >
          Edit Question
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button
          block={true}
          htmlType="button"
          onClick={(e) => {
            delQuestion(e, question.question_seq)
          }}
        >
          Delete
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Card
      className="question-card"
      title={`Question #${question.question_seq}`}
      extra={
        <Dropdown overlay={questionActions} trigger={['click']}>
          <a href="javascript:void(0)" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            <EllipsisOutlined />
          </a>
        </Dropdown>
      }
    >
      <Row>
        <Col lg={16} xs={24}>
          <Row>
            <Col>
              <div><p><strong>Question:</strong> {question.question}</p></div>
            </Col>
          </Row>
          <Row>
            <Col><p><strong>Answers: </strong></p></Col>
          </Row>
          <List
            size="small"
            grid={{ gutter: 16, column: 4 }}
            dataSource={question.answers}
            renderItem={ans => {
              const index = question.answers.indexOf(ans) + 1;
              return (<List.Item>
                <Card
                  size="small"
                  title={ANSWERS_INDEXES[index - 1]}
                  className={`answer ${index === question.correct_answer ? 'correct' : ''}`}
                >
                  {ans}
                </Card>
              </List.Item>)
            }}
          />
        </Col>
      </Row>
    </Card>
  )
}

Question.propTypes = {
  question: PropTypes.shape({
    question_seq: PropTypes.string,
    question: PropTypes.string,
    correct_answer: PropTypes.string,
    answers: PropTypes.array
  }),
  triggerEditQuestion: PropTypes.func,
  delQuestion: PropTypes.func
}

export default Question;
