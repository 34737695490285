/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import GameList from '../GamesList';
import SingleGame from '../SingleGame';
import GameForm from '../GameForm';
import ActiveGame from '../ActiveGame';
import NextEpisodeForm from '../NextEpisodeForm';
import Leaderboard from '../Leaderboard';
import Login from '../Login';
import VerifyUser from '../VerifyUser';
import PageNotFound from '../PageNotFound';
import * as UserActions from '../../store/actions/user';
import { Layout, Menu } from 'antd';
import {
  HomeOutlined,
  ScheduleOutlined
} from '@ant-design/icons';
import logoGreyedOut from '../../assets/logos/logo-greyed-out.png';
import './Root.scss';
import { UserOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

const menuItems = [
  { key: '1', icon: <HomeOutlined />, label: 'Λίστα επεισοδίων', path: '/' },
  { key: '2', icon: <ScheduleOutlined />, label: 'Προγραμματισμός Επεισοδίου', path: '/set-next-episode' }
]

const Root = ({ logout, getUser, authenticated, nickname, session_id, getGames, isLoggingIn }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [nname, setNname] = useState('');
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(menuItems.find(_item => _item.path !== '/' && location.pathname.startsWith(_item.path))?.key || '1');

  let ls_session_id = localStorage.getItem('session_id');

  const toggle = () => {
    setCollapsed(!collapsed);
  }

  useEffect(() => {
    if (authenticated && ls_session_id != null) {
      getUser(localStorage.getItem('email'))
        .then((res) => {
          setNname(res.nickname);
        })
        .catch(err => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, ls_session_id]);

  useEffect(() => {
    if (location.pathname.indexOf('active-game') !== -1) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
    setSelectedKey(menuItems.find(_item => _item.path !== '/' && location.pathname.startsWith(_item.path))?.key || '1');
  }, [location]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="nav-title">
          <img className="header-logo" src={logoGreyedOut} width={80} alt="logo" />
          <h3 className={`side-bar-title ${collapsed ? 'hidden' : ''}`}>EEY Administation</h3>
        </div>
        {authenticated
          ? (<Menu theme="dark" mode="inline" selectedKeys={selectedKey}>
            {
              menuItems.map(item => (
                <Menu.Item key={item.key} icon={item.icon}>
                  <Link to={item.path}>
                    {item.label}
                  </Link>
                </Menu.Item>
              ))
            }
            <SubMenu key="sub1" icon={<UserOutlined />} title={nname}>
              <Menu.Item key={menuItems.length + 1}>
                <a
                  href="#"
                  className="admin-menu-item"
                  onClick={(e) => {
                    e.preventDefault();
                    logout();
                  }}>Logout</a>
              </Menu.Item>
            </SubMenu>
          </Menu>)
          : null
        }
        <div className="sider-trigger" onClick={() => toggle()}>
          {collapsed
            ? <RightOutlined />
            : <LeftOutlined />
          }
        </div>
      </Sider>
      <Layout className="site-layout">
        <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280
          }}
        >
          <Switch>
            <Route exact path="/" component={GameList}></Route>
            <Route exact path="/login" component={Login}></Route>
            <Route exact path="/verify" component={VerifyUser}></Route>
            <Route exact path="/game" component={GameForm}></Route>
            <Route exact path="/game/:id" component={SingleGame}></Route>
            <Route exact path="/active-game/:id" component={ActiveGame}></Route>
            <Route exact path="/leaderboard/:gameId/:gameDate" component={Leaderboard}></Route>
            <Route exact path="/set-next-episode/:id?" component={NextEpisodeForm}></Route>
            <Route component={PageNotFound}></Route>
          </Switch>
        </Content>
      </Layout>
    </Layout>
  )
}

Root.propTypes = {
  logout: PropTypes.func,
  getUser: PropTypes.func,
  authenticated: PropTypes.bool,
  nickname: PropTypes.string,
  session_id: PropTypes.string,
  getGames: PropTypes.func,
  isLoggingIn: PropTypes.bool
};

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
  session_id: state.user.session_id,
  nickname: state.user.nickname || '',
  isLoggingIn: state.user.loading
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(UserActions.logout()),
  getUser: (userEmail) => dispatch(UserActions.getUser(userEmail))
});

export default connect(mapStateToProps, mapDispatchToProps)(Root);
