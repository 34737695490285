import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PageHeader, Card, Row, Col, Table, Statistic } from 'antd';
import moment from 'moment';
import * as GamesActions from '../../store/actions/games';
// import LeaderboardTable from '../../components/LeaderboardTable';
import { getLeaderboard, getStats } from '../../api/cpanel';
// import getFormattedDate from '../../utils/getFormattedDate';

const Leaderboard = ({ match }) => {
  const { gameId, gameDate } = match.params;
  // const [playedGames, setPlayedGames] = useState([]);
  // const [selectedDate, setSelectedDate] = useState(null);
  const [lt, setLt] = useState(null);
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({
    rank1: 0,
    referred_users: null,
    total_players: 5,
    total_users: 19
  });

  console.log(gameDate);
  const columns = [
    {
      title: 'Rank',
      dataIndex: 'rank',
      key: 'rank'
    },
    {
      title: 'Nickname',
      dataIndex: 'nickname',
      key: 'nickname'
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    }
  ];

  // const renderGLB = (game_id) => {
  //   getLeaderboard(game_id)
  //     .then(res => {
  //       setLt(res);
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     })
  // }

  // const onSelect = (value, mode) => {
  //   setSelectedDate(value);
  // }

  // useEffect(() => {
  //   // moment(Date.now()).unix();
  //   let selectedTS = moment(selectedDate).unix();
  //   console.log(selectedTS);
  //   let rGame = playedGames.map(game => {
  //     let endTS = game.end_timestamp; //TS already in unix format
  //     console.log(moment(endTS));
  //   })
  // }, [selectedDate, playedGames]);

  // useEffect(() => {
  //   let oldames = fetchedGames.filter(game => game.end_timestamp != null && game.end_timestamp !== '');
  //   setPlayedGames(oldames);
  // }, [fetchedGames]);

  useEffect(() => {
    // getGames().catch(err => console.log(err));
    setLoading(true);
    getLeaderboard(gameId)
      .then(res => {
        setLt(res);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setLoading(false));

    getStats(gameId)
      .then(res => setStats(res.stats))
      .catch(err => console.log(err));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      <PageHeader
        ghost={false}
        title={`Πίνακες νικητών για το Επεισόδιο ${gameId} (${moment.unix(gameDate).format('DD/MM/YYYY')}). `}
      />
      <Card>
        {/* <Row>
          <Col>
            <h3>Επιλέξτε ημερομηνία στο ημερολόγιο για να δείτε τον πίνακα νικητών για την συγκεκριμένη ημέρα.</h3>
          </Col>
        </Row> */}
        <Row gutter={16}>
          <Col xl={12} lg={24}>
            <Table
              dataSource={lt}
              columns={columns}
              loading={loading}
            />
          </Col>
          <Col xl={12} lg={24}>
            <Row gutter={16}>
              <Col xl={12} lg={6} sm={24}>
                <Statistic title="Εγγεγραμμένοι παίκτες" value={stats.total_users} />
              </Col>
              <Col xl={12} lg={6} sm={24}>
                <Statistic title="Σύνολο παικτών" value={stats.total_players} />
              </Col>
              <Col xl={12} lg={6} sm={24}>
                <Statistic title="Χρήστες από αναφορά" value={stats.referred_users || 0} />
              </Col>
              <Col xl={12} lg={6} sm={24}>
                <Statistic title="Πόντοι #1" value={stats.rank1} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </section>
  )
}

Leaderboard.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      gameId: PropTypes.string,
      gameDate: PropTypes.string
    })
  }),
  getGames: PropTypes.func,
  fetchedGames: PropTypes.array
}

const mapStateToProps = (state) => ({
  fetchedGames: state.games.list
});

const mapDispatchToProps = (dispatch) => ({
  getGames: () => dispatch(GamesActions.fetchGames())
});
export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);
