import _fetch from '../_fetch';
import 'url-search-params-polyfill';

const { REACT_APP_API_URL } = process.env;

/**
 * Trigger the presentation of the correct Answer
 * @returns {Promise}
  */
export default function presentAnswer(options = {}) {
  let urlencoded = new URLSearchParams();
  urlencoded.append('cmd', 'present_correct_answer');

  const url = `${REACT_APP_API_URL}/cpanel`;
  const config = {
    method: 'POST',
    body: urlencoded,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    ...options.reqConfig
  };
  return _fetch(url, {
    withAuth: true,
    ...options
  }, config);
}
