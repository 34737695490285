import 'url-search-params-polyfill';
export default function makeUrlSearchParams(params = {}) {
  let urlencoded = new URLSearchParams();
  if (Object.keys(params).length === 0 || params.constructor !== Object) {
    return '';
  }

  // eslint-disable-next-line array-callback-return
  Object.keys(params).map((key, index) => {
    if(Array.isArray(params[key])) {
      urlencoded.append(key, JSON.stringify(params[key]));
    } else {
      urlencoded.append(key, params[key]);
    }
  });

  return urlencoded;
}
