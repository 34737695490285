import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Button, Input, Divider, Col, Row, Select, message } from 'antd';
import * as QuestionActions from '../../store/actions/questions';
// import saveQuestion from '../../api/questions/saveQuestion';
import './QuestionForm.scss';

const { Option } = Select;
// Validation messages for Question form
const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: 'Το πεδίο είναι υποχρεωτικό!'
};
const ANSWERS_INDEXES = ['A', 'B', 'C', 'D'];

const QuestionForm = props => {
  const { gameId, questionSeq, q, onCancel } = props;
  const [form] = Form.useForm();
  // State declarations
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});

  /**
   * Function that will executed when the form will be submitted.
   * @param values {Object} form input values
  */
  const onFormSubmit = values => {
    setPayload({
      game_id: gameId,
      question_seq: (questionSeq),
      ...values
    });

    setLoading(true);
  };

  //TODO maybe move the saving functionality on the parent component?
  // Hook to handle component's lifecycle events
  useEffect(() => {
    if (loading) {
      props.saveQuestion(payload).then(res => {
        if (res && res.error) {
          message.error(res.message);
        } else {
          message.success('Η ερώτηση αποθηκεύτηκε επιτυχώς!');
          form.resetFields();
          props.onSuccess();
        }
      }).catch(err => {
        console.log(err);
        message.error('Κάποιο σφάλμα προέκυψε, παρακαλώ δοκιμάστε ξανά.');
      }).finally(() => {
        setLoading(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="question-form"
      initialValues={q}
      onFinish={onFormSubmit}
      validateMessages={validateMessages}
    >
      <Form.Item
        name={['question']}
        label={`Question`}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Row gutter={16}>
        {ANSWERS_INDEXES.map((letter) => {
          const index = ANSWERS_INDEXES.indexOf(letter);
          return (
            <Col span={6} key={letter}>
              <Form.Item
                key={index}
                name={['answers', index]}
                label={letter}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
          )
        })}
      </Row>
      <Form.Item
        name={['correct_answer']}
        label="Correct Answer"
        rules={[{ required: true }]}
      >
        <Select
          placeholder="Select the correct answer"
          allowClear
          style={{ width: 210 }}
        >
          <Option value="1">A</Option>
          <Option value="2">B</Option>
          <Option value="3">C</Option>
          <Option value="4">D</Option>
        </Select>
      </Form.Item>
      <Divider />
      <Form.Item>
        <Button
          className="mr-2"
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Submit
        </Button>
        <Button
          htmlType="button"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
}

QuestionForm.propTypes = {
  gameId: PropTypes.string,
  questionSeq: PropTypes.number,
  q: PropTypes.object,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  saveQuestion: PropTypes.func
};

const mapStateToProps = state => {
  return { };
}

const mapDispatchToProps = (dispatch) => ({
  saveQuestion: (payload) => dispatch(QuestionActions.saveQuestion(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionForm);
