/**
 * Collocate selectors from reducers.
 *
 * @param {Object} selectors The selectors object exported from reducers.
 * @param {String} stateKey The key of the state under the selectors are stored.
 * @returns {Object} The final collocated selectors object.
 */
export default function collocateSelectors(selectors, stateKey) {
    return Object.keys(selectors).reduce(function selectorsReducer(accum, key) {
        const keys = stateKey.split('.');

        if (keys.length === 1) {
            accum[key] = (state, ...restArgs) => selectors[key](state[stateKey], ...restArgs);
            return accum;
        }

        accum[key] = (state, ...restArgs) => {
            const finalState = keys.reduce((stateAccum, currentStateKey) => stateAccum[currentStateKey], state);
            return selectors[key](finalState, ...restArgs);
        };

        return accum;
    }, {});
}
