import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import './Notification.scss';

const Notification = ({ visible=false, icon, title, subtitle, type, banner, extra }) => {
  if(!visible) {
    return null;
  }
  return (
    <section className={`notification notification-${type}`} >
      <div id="notification-banner">{banner}</div>
      <Row gutter={24}>
        <Col sm={1} xs={24}>
          <span className="notification-icon-wrapper">{icon}</span></Col>
        <Col sm={15} xs={24}>
          <p className="notification-title">{title}</p>
          <p className="notification-subtitle">{subtitle}</p>
        </Col>
        <Col className="pull-right">
          {extra}
        </Col>
      </Row>
    </section>
  );
}

Notification.propTypes = {
  visible: PropTypes.bool,
  type: PropTypes.oneOf(['warning', 'error', 'none']),
  icon: PropTypes.element,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  banner: PropTypes.string,
  extra: PropTypes.arrayOf(PropTypes.element)
}

export default Notification;
