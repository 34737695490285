import * as ActionTypes from '../actiontypes/index';
import * as GamesCalls from '../../api/games';

/**
 * Requests all available games from the server.
 *
 * @returns {function(*): Promise<T>}
 */
export const fetchGames = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.GAMES_FETCH_IN_PROGRESS
    });

    return GamesCalls.fetchGames()
      .then(response => {
        dispatch({
          type: ActionTypes.GAMES_FETCH_SUCCESS,
          results: response
        });
        return response;
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.GAMES_FETCH_FAIL,
          error
        });
        return Promise.reject(error);
      });
  };
};

/**
 * Save a new game.
 *
 * @returns {function(*): Promise<T>}
 */
export const saveGame = (payload, options={}) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ADD_GAME_IN_PROGRESS
    });

    return GamesCalls.saveGame(payload, options)
      .then(response => {
        if(response && response.error) {
          dispatch({
            type: ActionTypes.ADD_GAME_FAIL,
            error: response.message
          });
          return Promise.reject(response.message);
        } else {
          dispatch({
            type: ActionTypes.ADD_GAME_SUCCESS
          });
        }
        return response;
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.ADD_GAME_FAIL,
          error
        });
        return Promise.reject(error);
      });
  };
};

/**
 * Requests all countries with available doctors for teleconsultation.
 *
 * @returns {function(*): Promise<T>}
 */
export const deleteGame = (payload, options={}) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.DELETE_GAME_IN_PROGRESS
    });

    return GamesCalls.deleteGame(payload, options)
      .then(response => {
        if(response && response.error) {
          dispatch({
            type: ActionTypes.DELETE_GAME_FAIL,
            error: response.message
          });
          return Promise.reject(response.message);
        } else {
          dispatch({
            type: ActionTypes.DELETE_GAME_SUCCESS
          });
        }
        return response;
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.DELETE_GAME_FAIL,
          error
        });
        return Promise.reject(error);
      });
  };
};
