import * as ActionTypes from '../actiontypes/index';
import * as CpanelCalls from '../../api/cpanel';

/**
 * Requests all available games from the server.
 *
 * @returns {function(*): Promise<T>}
 */
export const getState = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.GET_STATE_IN_PROGRESS
    });

    return CpanelCalls.getState()
      .then(response => {
        if (response.errors) {
          dispatch({
            type: ActionTypes.GET_STATE_FAIL,
            error: response.errors
          });
          return Promise.reject(response.errors[0]);
        }
        dispatch({
          type: ActionTypes.GET_STATE_SUCCESS,
          results: response
        });
        return response;
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.GET_STATE_FAIL,
          error
        });
        return Promise.reject(error);
      });
  };
};

/**
 * Requests all countries with available doctors for teleconsultation.
 *
 * @returns {function(*): Promise<T>}
 */
export const startEpisode = (gameId, options={}) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.START_EPISODE_IN_PROGRESS
    });

    return CpanelCalls.startEpisode(gameId, options)
      .then(response => {
        if (response.errors) {
          dispatch({
            type: ActionTypes.START_EPISODE_FAIL,
            error: response.errors
          });
          return Promise.reject(response.errors[0]);
        }
        dispatch({
          type: ActionTypes.START_EPISODE_SUCCESS,
          results: response
        });
        return response;
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.START_EPISODE_FAIL,
          error
        });
        return Promise.reject(error);
      });
  };
};

/**
 * Requests all countries with available doctors for teleconsultation.
 *
 * @returns {function(*): Promise<T>}
 */
export const setNextEpisode = (payload, options={}) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_NEXT_EPISODE_IN_PROGRESS
    });

    return CpanelCalls.setNextEpisode(payload, options)
      .then(response => {
        if (response.errors) {
          dispatch({
            type: ActionTypes.SET_NEXT_EPISODE_FAIL,
            error: response.errors
          });
          return Promise.reject(response.errors[0]);
        }
        dispatch({
          type: ActionTypes.SET_NEXT_EPISODE_SUCCESS,
          results: response
        });
        return response;
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.SET_NEXT_EPISODE_FAIL,
          error
        });
        return Promise.reject(error);
      });
  };
};

/**
 * Requests all countries with available doctors for teleconsultation.
 *
 * @returns {function(*): Promise<T>}
 */
export const presentAnswers = (payload, options={}) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.PRESENT_ANSWERS_IN_PROGRESS
    });

    return CpanelCalls.presentAnswers(payload, options)
      .then(response => {
        if (response.errors) {
          dispatch({
            type: ActionTypes.PRESENT_ANSWERS_FAIL,
            error: response.errors
          });
          return Promise.reject(response.errors[0]);
        }
        dispatch({
          type: ActionTypes.PRESENT_ANSWERS_SUCCESS,
          results: response
        });
        return response;
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.PRESENT_ANSWERS_FAIL,
          error
        });
        return Promise.reject(error);
      });
  };
};

/**
 * Requests all countries with available doctors for teleconsultation.
 *
 * @returns {function(*): Promise<T>}
 */
export const presentAnswer = (options={}) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.PRESENT_ANSWER_IN_PROGRESS
    });

    return CpanelCalls.presentAnswer(options)
      .then(response => {
        if (response.errors) {
          dispatch({
            type: ActionTypes.PRESENT_ANSWER_FAIL,
            error: response.errors
          });
          return Promise.reject(response.errors[0]);
        }
        dispatch({
          type: ActionTypes.PRESENT_ANSWER_SUCCESS,
          results: response
        });
        return response;
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.PRESENT_ANSWER_FAIL,
          error
        });
        return Promise.reject(error);
      });
  };
};

/**
 * Requests all countries with available doctors for teleconsultation.
 *
 * @returns {function(*): Promise<T>}
 */
export const startAnswering = (options={}) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.START_ANSWERING_IN_PROGRESS
    });

    return CpanelCalls.startAnswering(options)
      .then(response => {
        if (response.errors) {
          dispatch({
            type: ActionTypes.START_ANSWERING_FAIL,
            error: response.errors
          });
          return Promise.reject(response.errors[0]);
        }
        dispatch({
          type: ActionTypes.START_ANSWERING_SUCCESS,
          results: response
        });
        return response;
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.START_ANSWERING_FAIL,
          error
        });
        return Promise.reject(error);
      });
  };
};

/**
 * Requests all countries with available doctors for teleconsultation.
 *
 * @returns {function(*): Promise<T>}
 */
export const setOverlay = (data, options={}) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_OVERLAY_IN_PROGRESS
    });

    return CpanelCalls.setOverlay(data, options)
      .then(response => {
        if (response.errors) {
          dispatch({
            type: ActionTypes.SET_OVERLAY_FAIL,
            error: response.errors
          });
          return Promise.reject(response.errors[0]);
        }
        dispatch({
          type: ActionTypes.SET_OVERLAY_SUCCESS,
          results: response
        });
        return response;
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.SET_OVERLAY_FAIL,
          error
        });
        return Promise.reject(error);
      });
  };
};
