import * as ActionTypes from '../actiontypes/index';
import * as QuestionsCalls from '../../api/questions';

/**
 * Requests all available questions for a specific game from the server.
 *
 * @param {number} gameId id of the game to fetch questions for
 * @param {Object} options options to enhance the request
 * @returns {function(*): Promise<T>}
 */
export const fetchQuestions = (gameId, options={}) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.QUESTIONS_FETCH_IN_PROGRESS
    });

    return QuestionsCalls.fetchQuestions(gameId, options)
      .then(response => {
        dispatch({
          type: ActionTypes.QUESTIONS_FETCH_SUCCESS,
          results: response
        });
        return response;
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.QUESTIONS_FETCH_FAIL,
          error
        });
        return Promise.reject(error);
      });
  };
};

/**
 * Save a question. If the id of the question already exists,
 * the backend will just overwrite with new data
 * @param {Object} data the data object for the question
 * @param {Object} options options to enhance the request
 * @returns {function(*): Promise<T>}
 */
export const saveQuestion = (data, options={}) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.ADD_QUESTION_IN_PROGRESS
    });

    return QuestionsCalls.saveQuestion(data, options)
      .then(response => {
        if(response && response.error) {
          dispatch({
            type: ActionTypes.ADD_QUESTION_FAIL,
            error: response.message
          });
          return Promise.reject(response.message);
        } else {
          dispatch({
            type: ActionTypes.ADD_QUESTION_SUCCESS,
            results: response
          });
        }
        return response;
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.ADD_QUESTION_FAIL,
          error
        });
        return Promise.reject(error);
      });
  };
};

/**
 * Delete the question with a specific id
 *
 * @returns {function(*): Promise<T>}
 */
export const deleteQuestion = (data, options={}) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.DELETE_QUESTION_IN_PROGRESS
    });

    return QuestionsCalls.deleteQuestion(data, options)
      .then(response => {
        dispatch({
          type: ActionTypes.DELETE_QUESTION_SUCCESS,
          results: response
        });
        return response;
      })
      .catch(error => {
        dispatch({
          type: ActionTypes.DELETE_QUESTION_FAIL,
          error
        });
        return Promise.reject(error);
      });
  };
};
