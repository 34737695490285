import _fetch from '../_fetch';
import makeUrlSearchParams from '../utils/makeUrlSearchParams';

const { REACT_APP_API_URL } = process.env;

export default function deleteQuestion(data, options = {}) {
  let urlencoded = makeUrlSearchParams(data);

  const url = `${REACT_APP_API_URL}/questions`;
  const config = {
    method: 'DELETE',
    body: urlencoded,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    ...options.reqConfig
  };
  return _fetch(url, {
    withAuth: true,
    ...options
  }, config);
}
