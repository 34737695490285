import _fetch from '../_fetch';
import makeUrlSearchParams from '../utils/makeUrlSearchParams';

const { REACT_APP_API_URL } = process.env;

/**
 * Trigger the presentation of the correct Answer
 * @returns {Promise}
  */
export default function setOverlay(data = {}, options = {}) {
  const tempdata = {
    ...data,
    cmd: 'set_overlay'
  };

  let urlencoded = makeUrlSearchParams(tempdata);

  const url = `${REACT_APP_API_URL}/cpanel`;
  const config = {
    method: 'POST',
    body: urlencoded,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    ...options.reqConfig
  };
  return _fetch(url, {
    withAuth: true,
    ...options
  }, config);
}
