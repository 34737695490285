import _fetch from '../_fetch';
import 'url-search-params-polyfill';

const { REACT_APP_API_URL } = process.env;

export default function loginUser(userEmail, options = {}) {
  let urlencoded = new URLSearchParams();
  urlencoded.append('email', userEmail);

  const url = `${REACT_APP_API_URL}/users/login`;
  const config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: urlencoded,
    redirect: 'follow',
    ...options.reqConfig
  };
  return _fetch(url, {
    withAuth: false, // TODO change to withAuth -> true
    ...options
  }, config);
}
