import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import rootReducer from './reducers';

const composeEnhancers = process.env.REACT_APP_ENV === 'local' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    name: 'milyen',
    actionsBlacklist: []
  })
  : compose;

const enhancer = composeEnhancers(applyMiddleware(reduxThunk));
const store = createStore(rootReducer, undefined, enhancer);
export const persistor = persistStore(store);

export default store;
