import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import './AnswersOptions.scss';

const OPTIONS = ['A', 'B', 'C', 'D'];

const AnswersOptions = ({ answers, inactive }) => {
  const renderAnswers = () => {
    if (answers == null) {
      return false;
    }
    return (
      <Row>
        {answers.map(answer => {
          return (
            <Col key={answer} xs={24} lg={12}>
              <div className={`${inactive ? 'inactive' : ''} answer-option`} >
                <span className="answer-indication">
                  {OPTIONS[answers.indexOf(answer)]}
                </span>
                {answer}
              </div>
            </Col>
          );
        })
        }
      </Row>
    )
  }
  return (
    <>
      {renderAnswers()}
    </>
  )
};

AnswersOptions.propTypes = {
  answers: PropTypes.array,
  inactive: PropTypes.bool
};

export default AnswersOptions;
