import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PageHeader, Divider, Card } from 'antd';
import * as QuestionActions from '../../store/actions/questions';
import Question from '../../components/Question';
import QuestionForm from '../../components/QuestionForm';
import Spinner from '../../components/Spinner';
import findMissingNumberInSeq from '../../utils/findMissingNumberInSeq';
import './SingleGame.scss';

const SingleGame = props => {
  const gameParams = props.match.params;

  // State declarations
  const [gameQuestions, setGameQuestions] = useState([]);
  const [editQuestion, setEditQuestion] = useState(null);
  const [startFetchingQuestions, setStartFetchingQuestions] = useState(false);
  const [deleteQuestionState, setDeleteQuestionState] = useState({
    payload: {},
    triggerDelQuestion: false
  });

  // Function that will fetch the questions
  const getQuestions = () => {
    setStartFetchingQuestions(true);
  }

  /**
   * Function that will delete a specific question from the list
   * @param e {event} the click event
   * @param questionId {number} the id of the question to be deleted
   *  */
  const delQuestion = (e, questionId) => {
    setDeleteQuestionState({
      payload: {
        game_id: gameParams.id,
        question_seq: questionId
      },
      triggerDelQuestion: true
    });
  }

  /**
   * Function to enable the edit mode on specific question
   * @param e {event} the click event
   * @param questionId {number} the id of the question to be edited
   */
  const triggerEditQuestion = (e, questionId) => {
    setEditQuestion(questionId);
  }

  // Function that will run when saving a question successfuly
  const onSuccess = () => {
    // getQuestions();
    setEditQuestion(null);
  }

  const onCancel = () => {
    setEditQuestion(null);
  }

  // Update remaining questions' sequence numbers
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateRemainingQuestions = useCallback(() => {
    const questionsSeqArray = gameQuestions.map(question => {
      return question.question_seq
    });
    let missingSeq = null;
    if (questionsSeqArray[0] !== 1) {
      missingSeq = 1; //  If we are missing number 1 we need to explicitly add it here.
    } else {
      //Get the missing sequense number of the questions
      missingSeq = findMissingNumberInSeq(questionsSeqArray)[0];
    }

    // No missing sequence number
    if (missingSeq == null || missingSeq.length === 0) {
      setDeleteQuestionState({
        payload: {},
        triggerDelQuestion: false
      });
      return false;
    }

    //Get the question that has the next sequence from the one deleted.
    const questionToUpdate = gameQuestions.find((question) => {
      return question.question_seq > missingSeq;
    });

    // If there is no question to udpate let's not send a wrong paylaod to the server
    if (questionToUpdate == null) {
      return false;
    }

    const payload = {
      ...questionToUpdate,
      question_seq: missingSeq //Update the sequence number of that question.
    };

    // Update the question with the new seq number
    props.saveQuestion(payload)
      .then(res => {
        setDeleteQuestionState({
          payload: {
            game_id: gameParams.id,
            question_seq: questionToUpdate.question_seq
          },
          triggerDelQuestion: true
        });
      })
      .catch(err => {
        console.log(err);
      });
  });

  // Handle the 'componentDidMount' like Effect
  useEffect(() => {
    // When the component mounts let's start fetching the questions list.
    getQuestions();
  }, []);

  useEffect(() => {
    // if (deleteQuestionState.triggerDelQuestion) {
    updateRemainingQuestions();
    // }

    //We want that effect to run only when the questions has been updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameQuestions]);

  useEffect(() => {
    // If we have triggered the Start Fetching we need to make
    // the api call to fetch the questions list
    if (startFetchingQuestions) {
      props.fetchQuestions(gameParams.id).catch((err) => {
        console.log(err);
      }).finally(() => {
        setStartFetchingQuestions(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startFetchingQuestions]);

  // Store store questions to compoent's state.
  useEffect(() => {
    setGameQuestions(props.questions);
  }, [props.questions]);

  // Hook to handle component's lifecycle events
  useEffect(() => {
    // Call the api to delete a specific question
    if (deleteQuestionState.triggerDelQuestion) {
      props.deleteQuestion(deleteQuestionState.payload)
        .catch(err => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteQuestionState]);

  // Function to render the list with the fetched questions (from the server)
  const renderQuestions = () => {
    return gameQuestions.map(q => {
      if (editQuestion === q.question_seq) {
        return (
          <Card title={`Question #${q.question_seq}`}>
            <QuestionForm
              gameId={gameParams.id}
              questionSeq={q.question_seq}
              question={q.question}
              answers={q.answers}
              correctAnswer={q.correct_answer}
              q={q}
              onSuccess={() => onSuccess()}
              onCancel={() => onCancel()}
            />
          </Card>
        );
      }
      return (
        <Fragment key={q.question_seq.toString()}>
          <Question
            question={q}
            triggerEditQuestion={triggerEditQuestion}
            delQuestion={delQuestion}
          />
          <Divider />
        </Fragment>
      )
    });
  }

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        title={`Επεισόδιο #${gameParams.id}`}
      >
      </PageHeader>
      <Divider />
      {
        startFetchingQuestions || deleteQuestionState.triggerDelQuestion
          ? (
            <Spinner isBlock={true} />
          )
          : (
            <>
              {renderQuestions()}
              { editQuestion === null && gameQuestions.length < 25
                ? (<Card title={'New Question Form'}>
                  <QuestionForm
                    gameId={gameParams.id}
                    questionSeq={gameQuestions.length + 1}
                    onSuccess={() => onSuccess()}
                  />
                </Card>
                )
                : null
              }
            </>
          )
      }
      <Divider />
    </Fragment>
  );
}

SingleGame.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  questions: PropTypes.array,
  fetchQuestions: PropTypes.func,
  saveQuestion: PropTypes.func,
  deleteQuestion: PropTypes.func
};

function mapStateToProps(state) {
  return {
    questions: state.questions.list
  };
}

const mapDispatchToProps = (dispatch) => ({
  fetchQuestions: (gameId) => dispatch(QuestionActions.fetchQuestions(gameId)),
  saveQuestion: (payload) => dispatch(QuestionActions.saveQuestion(payload)),
  deleteQuestion: (payload) => dispatch(QuestionActions.deleteQuestion(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleGame);
