import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Root from './screens/Root';
import './App.scss';

export const history = createBrowserHistory();

const App = () => {
  return (
    <div className="App">
      <Router history={history}>
        <Root />
      </Router>
    </div>
  );
};

export default (App);
