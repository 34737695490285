import React from 'react';
import { Row, Col } from 'antd';
import logo from '../../assets/logos/logo-greyed-out.png';

const GameNotStarted = () => {
  return (
    <Row>
      <Col span={12} offset={6}>
        <div className="text-center">
          <img src={logo} width={128} alt="logo" />
          <h1>Είσαστε ένα βήμα πριν την έναρξη του παιχνιδιού</h1>
          <p>Πατήστε το κουμπί έναρξη παιχνιδιού για να αρχίστετε να αλληλεπιδράσετε με το κοινό που παίζει</p>
        </div>
      </Col>
    </Row>
  )
}

export default GameNotStarted;
