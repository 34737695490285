/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Card,
  message,
  Row,
  Col
} from 'antd';
import * as UserActions from '../../store/actions/user';
import 'url-search-params-polyfill';
import './VerifyUser.scss'

const VerifyUser = ({ history, authUser }) => {
  let location = useLocation();
  const [authPayload, setAuthPayload] = useState({});
  const [authFailed, setAuthFailed] = useState(false)

  useEffect(() => {
    if (authPayload.secret != null && authPayload.email != null) {
      authUser(authPayload)
        .then(res => {
          message.success('Ο χρήστης αναγνωρίστηκε επιτυχώς');
          history.push('/');
        })
        .catch(err => {
          console.log(err);
          setAuthFailed(true);
          message.error('Δεν μπορεί να γίνει η αυθεντικοποίηση του χρήστη. Παρακαλώ δοκιμάστε ξανά.')
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authPayload]);

  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    let secret = searchParams.get('secret');
    let email = searchParams.get('email');
    if (secret != null && email != null) {
      setAuthPayload({
        secret,
        email
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Row justify="center" align="middle">
      <Col lg={14} md={18} xs={24}>
        <Card>
          {!authFailed
            ?<p>Γίνεται η αυθεντικοποίηση του χρήστη. Παρακαλώ περιμένετε...</p>
            :<p>Πατήστε <a onClick={() => history.push('login')}>εδώ</a> για να εισάγετε ξανά email</p>
          }
        </Card>
      </Col>
    </Row>
  )
};

VerifyUser.propTypes = {
  authUser: PropTypes.func,
  history: PropTypes.object
};
const mapDispatchToProps = (dispatch) => ({
  authUser: (payload) => dispatch(UserActions.authUser(payload))
});
export default connect(null, mapDispatchToProps)(VerifyUser);
