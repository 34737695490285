import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'antd';
import {HomeOutlined} from '@ant-design/icons';
import './PageNotFound.scss';
import img404 from '../../assets/images/404.png';

const PageNotFound = ({history}) => {
  return (
    <Card className="text-center">
      <h1>Σφάλμα 404</h1>
      <h2>O σύνδεσμος δεν αντιστοιχεί σε υπαρκτή σελίδα.</h2>
      <Button
        type="primary"
        icon={<HomeOutlined />}
        onClick={() => {
          history.push('/');
        }}
      >Αρχικη Σελιδα</Button>
      <div>
        <img src={img404} width="800" alt="404" />
      </div>
    </Card>
  )
}

PageNotFound.propTypes = {
  history: PropTypes.object
};

export default PageNotFound;
