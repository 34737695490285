export default function getFormattedDate(timestamp = 0) {
  if(timestamp === 0) {
    return false;
  }
  const timestampToDate = new Date(timestamp * 1000);
  const months = ['Ιαν', 'Φεβ', 'Μαρ', 'Απρ', 'Μαι', 'Ιουν', 'Ιουλ', 'Αυγ', 'Σεπτ', 'Οκτ', 'Νοεμ', 'Δεκ'];
  const month = months[timestampToDate.getMonth()];
  const date = timestampToDate.getDate();
  const hours = timestampToDate.getHours();
  const minutes = '0' + timestampToDate.getMinutes();

  // Will display time in 10:30:23 format
  const formattedTime = date + ' ' + month + ' | ' + hours + ':' + minutes.substr(-2);

  return formattedTime;
}
